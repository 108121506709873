import React from "react";

const SingleBlog = ({ blog }) => {
    if (!blog) {
        return <p>Loading...</p>;
    }
    return (
        <div className="single-blog-container">
            <h2 className="blog-title-single"><b>/</b> {blog.title}</h2>
            <p className="blog-content-single">{blog.content}</p>
        </div>
    );
};

export default SingleBlog;
