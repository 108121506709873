const Footer = () => {
    return (
        <footer>
            <div className='footer'>
            © Abdeali Jaroli {new Date().getFullYear()}. All rights reserved.
            </div>
        </footer>
    )
}

export default Footer
