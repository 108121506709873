import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="home">
      <div className="col-1">
        <h1>
          hi there,
          <br />
          I'm <span>Abdeali Jaroli</span>.
        </h1>
        <br />
        <h3>
          Software Engineer.
          <br />
          UI/UX Designer.
          <br />
          Community Manager.
        </h3>
        <p>
          <b
            style={{
              color: "#FCA59B",
              fontWeight: "900",
              fontFamily: "Open Sans",
            }}
          >
            /
          </b>{" "}
          Final year Computer Science Undergrad at VIT Vellore, and a Full-Stack Developer with a strong sense of delivering remarkable User Experience. Currently based in India.
        </p>
        <Link to="/contact">
          <button type="button" className="btn">
            Let's talk!
          </button>
        </Link>
      </div>
      <div className="col-2">
        <img className="img" src="assets/profile_pic.webp" alt="" />
      </div>
    </div>
  );
};

export default Home;
