const Contact = () => {
    return (
        <div className='contact'>
            <h3><b>/</b> Let's get in touch!</h3>
            <h4>☕ I'm always up for a good conversation, need not always be tech-related!</h4>
            <h4>🚀 If you have a side project or a business idea, reach out to me and we can bring it to life.</h4>
            <h4>👨‍🎓 Currently being a student, I also work as a freelancer for various software & design projects. </h4>
            <h4>👀 And well if you're a recruiter, I would be looking forward to internships/ppo.</h4>
            <div className='contact-imgs'>
                <div>
                    <a href='mailto:jaroliabdeali@gmail.com'><img className="contact-img" src="assets/mail.svg" alt="" /></a>
                    <h4 className="contact-name">Mail</h4>
                </div>
                <div>
                    <a href='https://github.com/abdealijaroli'><img className="contact-img" src="assets/github-img.svg" alt="" /></a>
                    <h4 className="contact-name">GitHub</h4>
                </div>
                <div>
                    <a href='https://www.linkedin.com/in/abdealijaroli/'><img className="contact-img" src="assets/linkedin.svg" alt="" /></a>
                    <h4 className="contact-name">LinkedIn</h4>
                </div>
                <div>
                    <a href='https://t.me/abdealijaroli'><img className="contact-img" src="assets/telegram.svg" alt="" /></a>
                    <h4 className="contact-name">Telegram</h4>
                </div>
            </div>
            
            <div className='contact-illust'>
                <img src='assets/contact-illust.svg' alt=''></img>
            </div>
        </div>
        
    )
}

export default Contact
