
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Footer from "./components/Footer";
import Skills from "./components/Skills";
import Projects from "./components/Projects";
//import Work from "./components/Work";
import Contact from "./components/Contact";
import Blogs from "./components/Blogs.jsx";
import SingleBlog from "./components/SingleBlog.jsx";
import { allBlogs } from "./components/blogs.json"


function App() {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    setBlogs(allBlogs)
  }, []);

  return (
    <Router>
      <div className="container">
        <Navbar />
        <Route path="/" exact render={(props) => <Home />}></Route>
        <Switch>

          <Route exact path="/blogs" render={() => <Blogs blogsAll={blogs} />} />

          <Route
            exact
            path="/blogs/:id"
            render={({ match }) => {

              const singleBlog = blogs.find((blog) => blog.id == match.params.id);

              if (!singleBlog) {
                return <p>Blog not found</p>;
              }
              return <SingleBlog blog={singleBlog} />;
            }}
          />

          <Route path="/skills">
            <Skills />
          </Route>
          <Route path="/projects">
            <Projects />
          </Route>
          {/* <Route path="/work">
            <Work />
          </Route> */}
          <Route path="/contact">
            <Contact />
          </Route>
          <Route
            path="/resume"
            component={() => {
              window.location.href = "https://bit.ly/ajr2022";
              return null;
            }}
          ></Route>
        </Switch>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
