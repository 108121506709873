import { Link, NavLink } from "react-router-dom"
import { useState } from "react"

const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false)

    const toggleMenu = () => setMenuOpen(!menuOpen);    // onClick => setMenuOpen: (menuOpen = true)
    const menuStyle = {
        display: menuOpen ? 'block' : '',               // here, if (menuOpen) returns true | empty '' denotes that the display property is dependent on the screen size display mentioned in the css resp.  
    };

    return (
        <div className="navbar">
            <Link to="/"><img src="assets/logo.svg" className="logo" alt="logo"></img></Link>
            <nav>
                <ul className='menuList' style={menuStyle}>
                    <li><NavLink to="/blogs" activeStyle={{
                        color: "#FCA59B"
                    }} onClick={toggleMenu}>blogs</NavLink></li>
                    <li><NavLink to="/skills" activeStyle={{
                        color: "#FCA59B"
                    }} onClick={toggleMenu}>skills</NavLink></li>
                    <li><NavLink to="/projects" activeStyle={{
                        color: "#FCA59B"
                    }} onClick={toggleMenu}>projects</NavLink></li>
                    {/* <li><NavLink to="/work" activeStyle={{
                        color: "#FCA59B"
                    }} onClick={toggleMenu}>work</NavLink></li> */}
                    <li><NavLink to="/contact" activeStyle={{
                        color: "#FCA59B"
                    }} onClick={toggleMenu}>contact</NavLink></li>
                </ul>
            </nav>
            <img src={menuOpen ? "assets/close-menu.svg" : "assets/menu.svg"} className="menu" alt="menu" onClick={toggleMenu}></img>
        </div>
    )
}

export default Navbar
