const Skills = () => {
  return (
    <div className="skills">
      <div className="tech-stack">
        <h3>
          <b>/</b> Tech Stack
        </h3>
        <div className="tech-icons">
          <div>
            <img className="skill-img" src="assets/cpp.svg" alt="" />
            <h4 className="skill-names">C++</h4>
          </div>
          <div>
            <img className="skill-img" src="assets/javascript.svg" alt="" />
            <h4 className="skill-names">JS/TS</h4>
          </div>
          <div>
            <img className="skill-img" src="assets/node.svg" alt="" />
            <h4 className="skill-names">Node.js</h4>
          </div>
          <div>
            <img className="skill-img" src="assets/react.svg" alt="" />
            <h4 className="skill-names">React.js</h4>
          </div>
          <div>
            <img className="skill-img" src="assets/next.svg" alt="" />
            <h4 className="skill-names">Next.js</h4>
          </div>
          <div>
            <img className="skill-img" src="assets/mongo.svg" alt="" />
            <h4 className="skill-names">MongoDB</h4>
          </div>
          <div>
            <img className="skill-img" src="assets/firebase.svg" alt="" />
            <h4 className="skill-names">Firebase</h4>
          </div>
          <div>
            <img className="skill-img" src="assets/python.svg" alt="" />
            <h4 className="skill-names">Python</h4>
          </div>
          <div>
            <img className="skill-img" src="assets/google-cloud.svg" alt="" />
            <h4 className="skill-names">GCP</h4>
          </div>
        </div>
      </div>
      <div className="design-stack">
        <h3>
          <b>/</b> Design Stack
        </h3>
        <div className="design-icons">
          <div>
            <img className="skill-img" src="assets/figma.svg" alt="" />
            <h4 className="skill-names">Figma</h4>
          </div>
          <div>
            <img className="skill-img" src="assets/adobexd.svg" alt="" />
            <h4 className="skill-names">Adobe XD</h4>
          </div>
          <div>
            <img className="skill-img" src="assets/kdenlive.svg" alt="" />
            <h4 className="skill-names">Kdenlive</h4>
          </div>
          <div className="skills-illust">
            <img src="assets/skills-illust.svg" alt=""></img>
          </div>
        </div>
      </div>
      <br/>
      <div className="design-stack" style={{ marginTop: "20px" }}>
        <h3>
          <b>/</b> Currently learning:
        </h3>
        <div className="design-icons">
          <div>
            <img className="skill-img" src="assets/go.svg" alt="" />
            <h4 className="skill-names">Golang</h4>
          </div>
          <div>
            <img className="skill-img" src="assets/svelte.svg" alt="" />
            <h4 className="skill-names">Svelte</h4>
          </div>
          <div>
            <img className="skill-img" src="assets/docker.svg" alt="" />
            <h4 className="skill-names">Docker</h4>
          </div>
          <div>
            <img className="skill-img" src="assets/aws.svg" alt="" />
            <h4 className="skill-names">AWS</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
