const Projects = () => {
    return (
        <div className='projects'>
           <div className='proj'>
                <img className='proj-img' src='assets/pm-api.svg' alt=''></img>
                <div className='proj-desc'>
                    <h4>/ <span style={{ color: '#FCA59B' }}>Product Manager API</span></h4>
                    <div className='desc'>An MVC Rest API built using Node.js, Express.js & MongoDB. Secured with JWT, and documented using Swagger.
                    </div>
                    <div className='proj-links'>
                        <a href='https://github.com/abdealijaroli/product-manager-api'><img src='assets/github.svg' alt=''></img></a>
                        <a href='https://github.com/abdealijaroli/product-manager-api'><img src='assets/link.svg' alt=''></img></a>
                    </div>
                </div>
            </div>
            <div className='proj'>
                <img className='proj-img' src='assets/hyperion-bg.png' alt=''></img>
                <div className='proj-desc'>
                    <h4>/ <span style={{ color: '#FCA59B' }}>Hyperion.</span></h4>
                    <div className='desc'>An open-source Telegram bot that serves you a catalog of ongoing and
                        upcoming coding contests on various programming sites.
                    </div>
                    <div className='proj-links'>
                        <a href='https://github.com/abdealijaroli/code-competition-watcher'><img src='assets/github.svg' alt=''></img></a>
                        <a href='https://hyperion.dscvit.com/'><img src='assets/link.svg' alt=''></img></a>
                    </div>
                </div>
            </div>
            <div className='proj'>
                <img className='proj-img' src='assets/classifiers.png' alt=''></img>
                <div className='proj-desc'>
                    <h4>/ <span style={{ color: '#FCA59B' }}>Prediction Classifiers.</span></h4>
                    <div className='desc' >Predicting diabetes in patients by comparing different Machine Learning Classifiers. Results based on highest accuracy.
                    </div>
                    <div className='proj-links'>
                        <a href='https://github.com/abdealijaroli/diabetes-classification-algorithms'><img src='assets/github.svg' alt=''></img></a>
                        <a href='https://github.com/abdealijaroli/diabetes-classification-algorithms'><img src='assets/link.svg' alt=''></img></a>
                    </div>
                </div>
            </div>
            <div className='proj'>
                <img className='proj-img' src='assets/os.png' alt=''></img>
                <div className='proj-desc'>
                    <h4>/ <span style={{ color: '#FCA59B' }}>Creating a bootable OS.</span></h4>
                    <div className='desc'>Creating a basic stand-alone operating system from scratch.
                    </div><br />
                    <div className='proj-links'>
                        <a href='https://github.com/abdealijaroli/operating-system-development'><img src='assets/github.svg' alt=''></img></a>
                        <a href='https://goor.me/J5jkn'><img src='assets/link.svg' alt=''></img></a>
                    </div>
                </div>
            </div>
            <div className='proj'>
                <img className='proj-img' src='assets/tasks-manager.png' alt=''></img>
                <div className='proj-desc'>
                    <h4>/ <span style={{ color: '#FCA59B' }}>Tasks Manager.</span></h4>
                    <div className='desc'>Tasks Manager Web App in React.
                    </div><br /><br />
                    <div className='proj-links'>
                        <a href='https://github.com/abdealijaroli/tasks-manager'><img src='assets/github.svg' alt=''></img></a>
                        <a href='https://github.com/abdealijaroli/tasks-manager'><img src='assets/link.svg' alt=''></img></a>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Projects
