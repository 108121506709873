import React from "react";
import { Link } from "react-router-dom";

const Blog = ({ title, content, id }) => (
    <div className="blog-card">
        <Link to={`/blogs/${id}`}>
            <h2 className="blog-title">{title}</h2>
            <div className="blog-content-wrapper">
                <p className="blog-content">{content}</p>
            </div>
        </Link>
    </div>
);


const Blogs = ({ blogsAll }) => {
    return (
        <div className="blogs-container">
            {
                blogsAll.map((blog, index) => (
                    <Blog key={index} title={blog.title} content={blog.content} id={blog.id} />
                ))}
        </div>
    );
};

export default Blogs;
